import * as React from "react";
import { Component } from "react";
import { Input as AntInput, Select as AntSelect, InputNumber as AntInputNumber, AutoComplete as AntAutoComplete, Checkbox as AntCheckBox, Row, Col, Radio as AntRadio, Modal, DatePicker as AntDatePicker, Table } from "antd-min";
import {
    GLForm,
    GLFormComponentProps,
    FormItemsLayout,
    FormHelper,
    alignPop,
    GLUtil,
    MessageHelper,
    NotificationType,
    PrimaryLanguage,
    FormItemInModalParams,
    GLGlobal,
    LanguageDateFormatExceptYear,
    connect,
    defaultPhoneCountryCodeList,
    IPhoneCountryCode,
} from "gl-commonui";
import {
    IContentVersionService,
    ContentVersionModel,
    LessonPlanVersionModelPick,
    LessonPlanVersionStatus,
    MethodologyType,
    LessonPlanTemplateStatus,
    LessonPlanTemplateType,
    LessonPlanTemplateModelPick
} from "@app/service/admin/contentversion/index";
import {
    fmtMsg,
    CONSTS,
    lazyInject,
    TYPES,
    generateBillingStartMonths,
    GSAdminAction,
    lengthValider,
    StudentRegistrationType,
    DateHelper, RegionStatus,
    RegistrationModeType,
} from "@app/util/index";
import "../regionsedit.less";
import { GSAdminLocale, LinkManager } from "@app/locales/localeid";
import { EventManagerSettingsModel, RegionModelPropNames } from "@app/service/admin/regions/model";
import { SubmitBtns } from "@app/components";
import { IRegionGroupService } from "@app/service/admin/regiongroup";
import { ISchoolLicenseService } from "@app/service/admin/license";
import { PathConfig } from "@app/config/pathconfig";
import { IRegionService } from "@app/service/admin/regions";
import { CurriculumType } from "@app/service/class";
import { LinkManager as LinkManagerComponent } from './link-manager';
import { StateType } from "@app/states";
import { ILink, fetchLinksFromServer } from "@app/states/link";
import { fetchRegionEventSettings } from "@app/states/event-manager";
import _, { isUndefined } from 'lodash'
import { EventManager } from "./event-manager";
const withDisabledProps = (ChildComponent: any, condition?: any) => class WrappedComponent extends React.Component<any, any> {
    render() {
        if (condition) {
            return <ChildComponent {...this.props} disabled />
        }
        return <ChildComponent {...this.props} />
    }
}

const { Option } = AntSelect;
const { Option: AutoCompleteOption } = AntAutoComplete;
const { Group } = AntCheckBox;
const { Group: RadioGroup } = AntRadio;
const onlyViewAsGH = GLGlobal.isActionValid(GSAdminAction.ViewRegion) && !GLGlobal.isActionValid(GSAdminAction.EditRegion);
const Input = withDisabledProps(AntInput, onlyViewAsGH);
const Select = withDisabledProps(AntSelect, onlyViewAsGH);
const AutoComplete = withDisabledProps(AntAutoComplete, onlyViewAsGH);
const DatePicker = withDisabledProps(AntDatePicker, onlyViewAsGH);
const InputNumber = withDisabledProps(AntInputNumber, onlyViewAsGH);
const Checkbox = withDisabledProps(AntCheckBox, onlyViewAsGH);
const Radio = withDisabledProps(AntRadio, onlyViewAsGH);
const CheckBoxGroup = withDisabledProps(Group, onlyViewAsGH);
interface IContentVersion {
    gsVersionId: string;
    lsVersionId: string
}

interface FormViewProps extends GLFormComponentProps {
    isEdit: boolean
    regionId?: string
    history: any
    sourceData?: any
    linksFromServer: ILink[];
    linksSendToServer: ILink[];
    hasChange: boolean;
    fetchLinksFromServer: (d) => void;
    eventSettings: EventManagerSettingsModel[];
    fetchRegionEventSettings: (d) => void;
    hasEventSettingsChanged: boolean;
}
interface FormViewStates {
    unitMaxFlag?: boolean;
    defaultTimeZone?: string;
    defaultBillingDay?: string;
    defaultPrimaryLanguage?: string;
    defaultRegionGroup?: string;
    defaultGSContentVersion?: string;
    defaultLSContentVersion?: string;
    gsContentVersions?: JSX.Element[] | null;
    lsContentVersions?: JSX.Element[] | null;
    selectedContentVersion?: Partial<IContentVersion>;
    selectedRegionStatus?: RegionStatus;
    lessonPlanVersions?: LessonPlanVersionModelPick<"id" | "name">[];
    repMethodologyVersions?: LessonPlanVersionModelPick<"id" | "name">[];
    digitalLessonVersion?: LessonPlanVersionModelPick<"id" | "name">[];
    remoteLessonVersion?: LessonPlanVersionModelPick<"id" | "name">[];
    primaryLanguageOptions?: JSX.Element[] | null;
    regionGroupOptions?: JSX.Element[] | null;
    dirty?: boolean;
    registrationSelectedValues: any[];
    registrationModeValues: any[];
    disableSaveButton: boolean;
    visible: boolean;
    addLink: boolean;
    cancelLink: boolean;
    dataLink: any;
    countryCodeList: IPhoneCountryCode[];
    eventManagerVisible: boolean;
    isReadOnly: boolean;
}

const { renderFormItem } = FormHelper;
@GLForm.create()
@connect(
    ({ link: { linksFromServer, linksSendToServer, hasChange }, eventManager: { eventSettings, hasEventSettingsChanged } }: StateType) => {
        return {
            linksFromServer,
            linksSendToServer,
            hasChange,
            eventSettings,
            hasEventSettingsChanged
        };
    },
    {
        fetchLinksFromServer,
        fetchRegionEventSettings
    }
)
export class FormView extends Component<FormViewProps, FormViewStates> {
    @lazyInject(TYPES.IRegionService)
    service: IRegionService;
    @lazyInject(TYPES.IContentVersionService)
    contentVersionService: IContentVersionService;
    @lazyInject(TYPES.IRegionGroupService)
    regionGroupService: IRegionGroupService;
    @lazyInject(TYPES.ISchoolLicenseService)
    licenseService: ISchoolLicenseService;
    billingDayOptions?: JSX.Element[] | null;
    billingPeriodOptions?: JSX.Element[] | null;
    billingMonthOptions?: JSX.Element[] | null;
    timeZoneOptions?: JSX.Element[] | null = [];
    regionGroupId: null;
    gsVersionGroupId: null;
    lsVersionGroupId: null;
    defaultPhoneCode: string = "+1";
    constructor(props) {
        super(props);
        this.state = {
            registrationSelectedValues: this.props.isEdit ? this.setRegistrationValues() : [],
            registrationModeValues : this.props.isEdit ? this.setRegistrationModeValues() : [1], 
            unitMaxFlag: false, // default class Max,
            disableSaveButton: true,
            visible: false,
            eventManagerVisible: false,
            addLink: false,
            cancelLink: false,
            dataLink: [],
            countryCodeList: [],
            isReadOnly: onlyViewAsGH
        };
    }

    toggleLinkManager = () => {
        this.setState((prevState) => ({ visible: !prevState.visible }));
    };

    toggleEventManager = () => {
        this.setState((prevState) => ({ eventManagerVisible: !prevState.eventManagerVisible }));
    };

    setRegistrationValues() {
        let selectedType = this.props.sourceData.studentRegistrationTypeId;
        let types = [];
        if (selectedType == StudentRegistrationType.Both) {
            types.push(StudentRegistrationType.Generic);
            types.push(StudentRegistrationType.Manual);
        }
        else {
            types.push(selectedType);
        }

        return types;
    }

    setRegistrationModeValues() {
        let selectedType = this.props.sourceData.registrationModeTypeId;
        let types = [];
        if (selectedType == RegistrationModeType.Both) {
            types.push(RegistrationModeType.Email);
            types.push(RegistrationModeType.Phone);
        }
        else {
            types.push(selectedType);
        }
        return types;
    }

    componentDidMount() {
        this.getEventData();
        this.setPrimaryLanguageOptions();
        this.setTimeZoneOptions();
        this.setBillingDayOptions();
        this.setContentVersions();
        this.setRegionGroups();
        this.setBillingPeriodOptions();
        this.setBillingStartMonthOptions();
        this.setRegionStatus();
    }

    componentDidUpdate(previousProps: FormViewProps, previousState: FormViewStates) {
        const { selectedContentVersion: preContentVersion, selectedRegionStatus: preRegionStatus } = previousState;
        this.setLessonPlanVersions(preContentVersion);
        this.setLessonPlanVersionsOnRegionStatus(preRegionStatus);
    }

    getEventData() {
        //get data using region id and in case of create get active events
        const regionId = isUndefined(this.props.regionId) ? null : this.props.regionId;
        this.props.fetchRegionEventSettings({ regionId });
    }

    sortCodes = (data: IPhoneCountryCode[]) => {
        return _.sortBy(data, (item) => {
            const sCode = item.code.trim().split("+");
            if (sCode.length > 1) {
                return parseInt(sCode[1]);
            }
            return item;
        });
    }

    handleCountryCodeSearch = value => {
        let result: IPhoneCountryCode[] = [];
        if (!value) {
            result = [];
        } else {
            result = defaultPhoneCountryCodeList.filter(f => f.code.indexOf(value) > -1);
        }
        const sortedResults = this.sortCodes(result);
        this.setState({ countryCodeList: sortedResults });
    };

    getOption(item, index) {
        return (
            <Option key={index} value={index} title={item}>
                {item}
            </Option>
        );
    }
    getEmptyOption(value) {
        return (
            <Option key="empty" value={value}>
                &nbsp;
            </Option>
        );
    }
    setPrimaryLanguageOptions() {
        let tempOptions = Object.keys(PrimaryLanguage).map(language => {
            return this.getOption(PrimaryLanguage[language], language);
        });
        this.setState({
            defaultPrimaryLanguage: PrimaryLanguage["English"],
            primaryLanguageOptions: tempOptions
        });
    }
    setTimeZoneOptions() {
        for (let index in CONSTS.timeZones) {
            this.timeZoneOptions.push(
                this.getOption(CONSTS.timeZones[index], index)
            );
        }
        let tempTimeZone = CONSTS.defaultIndex.toString();
        let timezone = -new Date().getTimezoneOffset();
        if (timezone != 0) {
            tempTimeZone = timezone.toString();
        }
        this.setState({
            defaultTimeZone: tempTimeZone
        });
    }
    setBillingDayOptions() {
        this.billingDayOptions = CONSTS.billingDays.map((item, index) => {
            return this.getOption(item, item);
        });

        this.setState({
            defaultBillingDay: CONSTS.billingDays[CONSTS.defaultIndex]
        });
    }
    setContentVersions() {
        return this.contentVersionService
            .get<ContentVersionModel[]>()
            .then(data => {
                //this filters the GSv5 from grapeseed version dropdown in region edit screen we
                //must remove this change before GSv5 release to production.
                data = data.filter(v => v.name != 'GSv5');
                let gsContentVersionOptions = [];
                let lsContentVersionOptions = [];
                for (let item of data) {
                    if (item.curriculumType === CurriculumType.LittleSEED) {
                        lsContentVersionOptions.push(
                            this.getOption(item.name, item.id)
                        );
                    } else {
                        gsContentVersionOptions.push(
                            this.getOption(item.name, item.id)
                        );
                    }
                }

                const { isEdit, sourceData } = this.props;
                const defaultGSContentVersionKey = gsContentVersionOptions[CONSTS.defaultIndex].key;
                const defaultLSContentVersionKey = lsContentVersionOptions[CONSTS.defaultIndex].key;

                this.setState({
                    defaultLSContentVersion: defaultLSContentVersionKey,
                    lsContentVersions: lsContentVersionOptions,
                    defaultGSContentVersion: defaultGSContentVersionKey,
                    gsContentVersions: gsContentVersionOptions,
                    selectedContentVersion: isEdit
                        ? { gsVersionId: sourceData.gsVersionGroupId }
                        : { gsVersionId: defaultGSContentVersionKey }
                });
            });
    }

    setRegionStatus() {
        const { isEdit, sourceData } = this.props;
        this.setState({
            selectedRegionStatus: isEdit ? sourceData.status : RegionStatus.Enabled
        });
    }

    getAndSetLpVersion(versionId: string, regionStatus: RegionStatus) {
        this.contentVersionService
            .getLessonPlanVersions({
                versionGroupIds: [versionId]
            })
            .then(lessonPlanVersions => {
                const lessonPlanVersionDatas = lessonPlanVersions
                    .filter(version =>
                        !!version.id &&
                        version.methodology === MethodologyType.Classic && version.status &&
                        (regionStatus === RegionStatus.Test ? (version.status === LessonPlanVersionStatus.Internal || version.status === LessonPlanVersionStatus.Published)
                            : version.status === LessonPlanVersionStatus.Published))
                    .map(version =>
                        (({ id, name }) => ({ id, name }))(version)
                    )
                    .sort((left, right) =>
                        left.name.localeCompare(right.name, 'en')
                    );

                const repMethodologyVersionDatas = lessonPlanVersions
                    .filter(version =>
                        !!version.id &&
                        version.methodology === MethodologyType.REP && version.status &&
                        (regionStatus === RegionStatus.Test ? (version.status === LessonPlanVersionStatus.Internal || version.status === LessonPlanVersionStatus.Published)
                            : version.status === LessonPlanVersionStatus.Published))
                    .map(version =>
                        (({ id, name }) => ({ id, name }))(version)
                    )
                    .sort((left, right) =>
                        left.name.localeCompare(right.name, 'en')
                    );
                const digitalLessonVersionData = lessonPlanVersions
                    .filter(version =>
                        !!version.id &&
                        version.methodology === MethodologyType.Digital && version.status &&
                        (regionStatus === RegionStatus.Test ? (version.status === LessonPlanVersionStatus.Internal || version.status === LessonPlanVersionStatus.Published)
                            : version.status === LessonPlanVersionStatus.Published))
                    .map(version =>
                        (({ id, name }) => ({ id, name }))(version)
                    )
                    .sort((left, right) =>
                        left.name.localeCompare(right.name, 'en')
                    );
                const remoteLessonVersionData = lessonPlanVersions
                    .filter(version =>
                        !!version.id &&
                        version.methodology === MethodologyType.Remote && version.status &&
                        (regionStatus === RegionStatus.Test ? (version.status === LessonPlanVersionStatus.Internal || version.status === LessonPlanVersionStatus.Published)
                            : version.status === LessonPlanVersionStatus.Published))
                    .map(version =>
                        (({ id, name }) => ({ id, name }))(version)
                    )
                    .sort((left, right) =>
                        left.name.localeCompare(right.name, 'en')
                    );

                this.setState({
                    lessonPlanVersions: lessonPlanVersionDatas,
                    repMethodologyVersions: repMethodologyVersionDatas,
                    digitalLessonVersion: digitalLessonVersionData,
                    remoteLessonVersion: remoteLessonVersionData,
                });

                var gsLpVersion = this.props.form.getFieldValue("gsLessonPlanVersionId");
                var repLpVersion = this.props.form.getFieldValue("repMethodologyVersionId");
                var remoteLpVersion = this.props.form.getFieldValue("remoteLessonPlanVersionId");
                var digitalLpVersion = this.props.form.getFieldValue("digitalLessonPlanVersionId");

                if (gsLpVersion && !lessonPlanVersionDatas.some(lp => lp.id === gsLpVersion)) {
                    this.props.form.setFieldsValue({ "gsLessonPlanVersionId": null });
                }
                if (repLpVersion && !repMethodologyVersionDatas.some(lp => lp.id === repLpVersion)) {
                    this.props.form.setFieldsValue({ "repMethodologyVersionId": null });
                }
                if (digitalLpVersion && !digitalLessonVersionData.some(lp => lp.id === digitalLpVersion)) {
                    this.props.form.setFieldsValue({ "digitalLessonPlanVersionId": null });
                }
                if (remoteLpVersion && !remoteLessonVersionData.some(lp => lp.id === remoteLpVersion)) {
                    this.props.form.setFieldsValue({ "remoteLessonPlanVersionId": null });
                }
            });
    }

    setLessonPlanVersions(preContentVersion: Partial<IContentVersion>) {
        const { selectedContentVersion, selectedRegionStatus } = this.state;
        const shouldUpdate = selectedContentVersion && selectedRegionStatus && (
            !preContentVersion ||
            selectedContentVersion.gsVersionId !== preContentVersion.gsVersionId
        );

        shouldUpdate &&
            this.getAndSetLpVersion(selectedContentVersion.gsVersionId, selectedRegionStatus);
    }

    setLessonPlanVersionsOnRegionStatus(preRegionStatus?: RegionStatus) {
        const { selectedContentVersion, selectedRegionStatus } = this.state;
        const shouldUpdate = selectedContentVersion && selectedRegionStatus && (
            !preRegionStatus ||
            (selectedRegionStatus !== preRegionStatus)
        );

        shouldUpdate &&
            this.getAndSetLpVersion(selectedContentVersion.gsVersionId, selectedRegionStatus);
    }

    setRegionGroups() {
        return this.regionGroupService.getRegionGroups({}).then(data => {
            let regionGroupOptions = [];
            for (let item of data) {
                regionGroupOptions.push(this.getOption(item.name, item.id));
            }

            this.setState({
                defaultRegionGroup: data[CONSTS.defaultIndex].key,
                regionGroupOptions: regionGroupOptions
            });
        });
    }
    setBillingPeriodOptions() {
        this.billingPeriodOptions = CONSTS.billingPeriods.map((item, index) => {
            return this.getOption(item, item);
        });

        this.setState({
            defaultBillingDay: CONSTS.billingPeriods[CONSTS.defaultIndex]
        });
    }
    setBillingStartMonthOptions() {
        this.billingMonthOptions = generateBillingStartMonths().map((item, index) => {
            return this.getOption(item.text, item.value);
        });
    }

    setStudentType(values) {
        if (values.studentRegistrationSelectedValues.length == 2) {
            return StudentRegistrationType.Both
        }
        else {
            return values.studentRegistrationSelectedValues[0];
        }
    }

    setRegistrationModeType(values) {
        console.log(`values ${values.registrationModeValues.length}`);
        if (values.registrationModeValues.length == 2) {
            return RegistrationModeType.Both
        }
        else {
            return values.registrationModeValues[0];
        }
    }

    handleSubmit(e) {
        const focusElement = document.activeElement as HTMLElement;
        if (focusElement) {
            focusElement.blur();
        }
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            const { isEdit, regionId, sourceData, linksFromServer, linksSendToServer, hasChange, fetchLinksFromServer, hasEventSettingsChanged, eventSettings } = this.props;
            // const checkLinkHasChange = _.isEqual(linksFromServer, linksSendToServer)
            const genericLinks = { hasChange, data: linksSendToServer }
            const eventSettingsData = { hasChange: isUndefined(regionId) ? true : hasEventSettingsChanged, data: eventSettings }
            function saveRegion(self, regionId) {
                values.genericLinks = genericLinks;
                values.eventSettings = eventSettingsData;
                values.allowGenerateUnitPlan = !values.allowGenerateUnitPlan;
                values.allowPromoteClass = !values.allowPromoteClass;
                values.studentRegistrationTypeId = self.setStudentType(values);
                values.registrationModeTypeId = self.setRegistrationModeType(values);
                values.visitationResetDate = DateHelper.formatDate2ISO(values.visitationResetDate);
                self.service
                    .editRegion(values)
                    .then(data => {
                        fetchLinksFromServer({ regionId })
                        self.props.history.push({
                            pathname: GLUtil.pathStringify(PathConfig.Region, {
                                regionId
                            })
                        });
                        if (values.status && self.props.sourceData.status && values.status !== self.props.sourceData.status) {
                            if (RegionStatus.Disabled === values.status) {
                                MessageHelper.Message(
                                    NotificationType.Warning,
                                    fmtMsg({ id: GSAdminLocale.DisabledRegionTip })
                                );
                            }
                            else if (self.props.sourceData.status === RegionStatus.Disabled && (RegionStatus.Enabled === values.status || RegionStatus.Test === values.status)) {
                                MessageHelper.Message(
                                    NotificationType.Warning,
                                    fmtMsg({ id: GSAdminLocale.EnableRegionTip })
                                );
                            }
                        }
                    })
                    .catch(error => {
                        //console.log(error);
                    });
            }
            if (!err) {
                if (isEdit) {
                    values.id = regionId;
                    this.checkBillingInfoChangeAndSave(values, saveRegion);
                } else {
                    values.allowGenerateUnitPlan = !values.allowGenerateUnitPlan;
                    values.allowPromoteClass = !values.allowPromoteClass;
                    const genericLinks = { hasChange, data: linksSendToServer }
                    values.genericLinks = genericLinks;
                    values.eventSettings = eventSettingsData;
                    values.registrationModeTypeId = this.setRegistrationModeType(values);
                    this.service.createRegion(values).then(() => {
                        this.props.history.push({ pathname: PathConfig.Regions });
                    });
                }
            }
        });
    }
    checkBillingInfoChangeAndSave(values, saveRegion: (context, regionId) => void) {
        const { regionId, sourceData } = this.props;
        if ((sourceData.billingDay != values.billingDay)
            || (sourceData.billingPeriod != values.billingPeriod)) {

            this.licenseService.getRegionManualAdjustments({ regionId: regionId })
                .then(response => {
                    const numberOfAdjustments: number = response
                        ? response.reduce((sum, adj) => sum + adj.adjustmentCount, 0)
                        : 0;

                    if (numberOfAdjustments > 0) {
                        Modal.confirm({
                            title: fmtMsg({ id: GSAdminLocale.RegionBillingCycleWarning }),
                            okText: fmtMsg({ id: GSAdminLocale.ModelButtonYes }),
                            cancelText: fmtMsg({ id: GSAdminLocale.ModelButtonNo }),
                            onOk: () => {
                                saveRegion(this, regionId);
                            }
                        });
                    } else {
                        saveRegion(this, regionId);
                    }
                })
                .catch(() => {
                    saveRegion(this, regionId);
                });
        } else {
            saveRegion(this, regionId);
        }
    }
    backToList() {
        const { isEdit, regionId, history } = this.props;
        history.push({
            pathname: !isEdit ?
                PathConfig.Regions :
                GLUtil.pathStringify(PathConfig.Region, {
                    regionId: regionId
                })
        });
    }
    getTableColumns() {
        const { form } = this.props;
        return [
            {
                title: "Name",
                dataIndex: "name",
                width: "60%",
                render: text => {
                    return fmtMsg({ id: text });
                }
            },
            {
                title: "Value",
                dataIndex: "checked",
                className: "table-column",
                render: (text, record, index) => {
                    const options = {
                        formItemProps: { label: null },
                    }
                    if (record.key == RegionModelPropNames.registrationModeValues)
                    {
                        const registrationOptions = [
                            { label: fmtMsg({ id: GSAdminLocale.SchoolTeacherEmail }), value: RegistrationModeType.Email },
                            { label: fmtMsg({ id: GSAdminLocale.UserEditPhone }), value: RegistrationModeType.Phone },
                        ];
                        return renderFormItem(
                            { ...form, ...options },
                            GSAdminLocale.RegionRegistrationMode,
                            RegionModelPropNames.registrationModeValues,
                            <CheckBoxGroup className="class-max__radio-group" options={registrationOptions} />,
                            this.state.registrationModeValues,
                            null,
                            [{
                                required: true,
                                message: fmtMsg({ id: GSAdminLocale.RegionStudentRegistrationModeValidation })
                            }]
                        )
                    }
                    if (record.key == RegionModelPropNames.unitMaxFlag) {
                        return renderFormItem(
                            { ...form, ...options },
                            GSAdminLocale.RegionMaxUnitFlagText,
                            RegionModelPropNames.unitMaxFlag,
                            <RadioGroup
                                className="class-max__radio-group"
                                {...alignPop()}
                                onChange={e =>
                                    this.setState({ unitMaxFlag: e.target.value })
                                }
                            >
                                <Row type="flex">
                                    <Col>
                                        <Radio value={false}>
                                            {fmtMsg({ id: GSAdminLocale.RegionMaxUnitFlagClass })}
                                        </Radio>
                                    </Col>
                                    <Col>
                                        <Radio value={true}>
                                            {fmtMsg({ id: GSAdminLocale.RegionMaxUnitFlagSchool })}
                                        </Radio>
                                    </Col>
                                </Row>
                            </RadioGroup>,
                            text,
                            true
                        );
                    } else {
                        return form.getFieldDecorator(record.key as never, {
                            initialValue: text,
                            valuePropName: 'checked',
                        })(
                            <Checkbox></Checkbox>,
                        );
                    }
                },
            },
        ];
    }
    getTableData() {
        const { isEdit, sourceData } = this.props;
        return [
            {
                key: RegionModelPropNames.billForPartialMonthAdd,
                name: GSAdminLocale.RegionBillUserForPartialMonthAdd,
                checked: isEdit ? sourceData.billForPartialMonthAdd : false,
            },
            {
                key: RegionModelPropNames.billForPartialMonthRemove,
                name: GSAdminLocale.RegionBillUserForPartialMonthRemove,
                checked: isEdit ? sourceData.billForPartialMonthRemove : false,
            },
            {
                key: RegionModelPropNames.delayClassBilling,
                name: GSAdminLocale.RegionDelayClassBilling,
                checked: isEdit ? sourceData.delayClassBilling : false,
            },
            {
                key: RegionModelPropNames.allowSchoolEditUnit,
                name: GSAdminLocale.AllowSchoolEditUnit,
                checked: isEdit ? sourceData.allowSchoolEditUnit : true,
            },
            {
                key: RegionModelPropNames.allowSchoolEditLicense,
                name: GSAdminLocale.AllowSchoolEditLicense,
                checked: isEdit ? sourceData.allowSchoolEditLicense : true,
            },
            {
                key: RegionModelPropNames.allowMaterialRequest,
                name: GSAdminLocale.AllowMaterialRequest,
                checked: isEdit ? sourceData.allowMaterialRequest : true,
            },
            {
                key: RegionModelPropNames.allowSetTeacherContactParent,
                name: GSAdminLocale.AllowSetTeacherContactParent,
                checked: isEdit ? sourceData.allowSetTeacherContactParent : true,
            },
            {
                key: RegionModelPropNames.enforceUnitPlanSpacing,
                name: GSAdminLocale.EnforceUnitPlanSpacing,
                checked: isEdit ? sourceData.enforceUnitPlanSpacing : true,
            },
            {
                key: RegionModelPropNames.allowParentDownload,
                name: GSAdminLocale.AllowParentGSDownload,
                checked: isEdit ? sourceData.allowParentDownload : true,
            },
            {
                key: RegionModelPropNames.allowParentLsdownload,
                name: GSAdminLocale.AllowParentLSDownload,
                checked: isEdit ? sourceData.allowParentLsdownload : true
            },
            {
                key: RegionModelPropNames.allowSetParentContactTeacher,
                name: GSAdminLocale.AllowSetParentContactTeacher,
                checked: isEdit ? sourceData.allowSetParentContactTeacher : true,
            },
            {
                key: RegionModelPropNames.allowGenerateUnitPlan,
                name: GSAdminLocale.RegionDisableUnitPlanGeneration,
                checked: isEdit ? sourceData.disableUnitPlanGeneration : false,
            },
            {
                key: RegionModelPropNames.allowPromoteClass,
                name: GSAdminLocale.RegionDisableClassPromotion,
                checked: isEdit ? sourceData.disableClassPromotion : false,
            },
            {
                key: RegionModelPropNames.enableCodeLogin,
                name: GSAdminLocale.RegionEnableEasyLogin,
                checked: isEdit ? sourceData.enableCodeLogin : false,
            },
            {
                key: RegionModelPropNames.enablePhoneRegistration,
                name: GSAdminLocale.RegionEnablePhoneBasedLogin,
                checked: isEdit ? sourceData.enablePhoneRegistration : false,
            },
            {
                key: RegionModelPropNames.registrationModeValues,
                name: GSAdminLocale.RegionRegistrationMode,
                checked: isEdit ? sourceData.isPhoneAllowed && sourceData.isEmailAllowed : false,
            },
            {
                key: RegionModelPropNames.unitMaxFlag,
                name: GSAdminLocale.RegionMaxUnitFlagText,
                checked: isEdit ? sourceData.unitMaxFlag : this.state.unitMaxFlag,
            },
            {
                key: RegionModelPropNames.agreeToMaterialRequestLicense,
                name: GSAdminLocale.RegionAgreeToMaterialRequestLicense,
                checked: isEdit ? sourceData.agreeToMaterialRequestLicense : false,
            },
            {
                key: RegionModelPropNames.allowPhoneSupport,
                name: GSAdminLocale.RegionAllowPhoneSupport,
                checked: isEdit ? sourceData.allowPhoneSupport : true,
            },{
                key: RegionModelPropNames.allowTermsAgreement,
                name: GSAdminLocale.RegionAllowTermsAgreement,
                checked: isEdit ? sourceData.allowTermsAgreement : false,
            },
        ];
    }
    disableDates(current) {
        const date = new Date();
        const year = date.getFullYear();
        const firstDayOfYear = new Date(year, 0, 1, 0, 0, 0);
        const lastDayOfYear = new Date(year, 12, 0, 24, 0, -1);
        return current < firstDayOfYear || current > lastDayOfYear;
    }

    onFormChange = () => {
        this.toggleDisableButton();
    }

    toggleDisableButton() {
        if (!this.state.disableSaveButton) {
            return;
        }
        this.setState({ disableSaveButton: false });
    }

    onVersionGroupChange = (form, { gsVersionId, lsVersionId }) => {
        if (gsVersionId !== this.state.selectedContentVersion.gsVersionId) {
            form.setFieldsValue({ "gsLessonPlanVersionId": null });
            form.setFieldsValue({ "repMethodologyVersionId": null });
            form.setFieldsValue({ "remoteLessonPlanVersionId": null });
            form.setFieldsValue({ "digitalLessonPlanVersionId": null });
        }
        this.setState({ selectedContentVersion: { gsVersionId, lsVersionId } });
    }

    onRegionStatusChange = (regionStatus) => {
        this.setState({ selectedRegionStatus: regionStatus });
    }

    renderCountryOptions = () => {
        return this.state.countryCodeList.map(item => {
            return <AutoCompleteOption key={item.code} value={item.code} label=" ">
                {item.code} ({item.country}&nbsp;-&nbsp;{item.counrtryAbbr})
            </AutoCompleteOption>;
        });
    };

    render() {
        const minCount = 1;
        const maxCount = 99;
        const defaultDeviceLimit = 3;
        const minDeviceThreshold = 0;
        const defaultDeviceThreshold = 2;
        const defaultMaxUnitsPerYear = 5;
        const defaultExtendedSubscriptionMonths = 12;
        const defaultCurrency = ""; //Currency.USD;
        const defaultLessonPlanVersion = null;
        const defaultREPMethodologyVersion = null;
        const defaultRemoteLessonVersion = null;
        const defaultDigitalLessonVersion = null;
        const { isEdit, form, sourceData, eventSettings } = this.props;
        const { selectedRegionStatus, selectedContentVersion: { gsVersionId, lsVersionId } = {}, isReadOnly } = this.state;
        const supportEmailParams: FormItemInModalParams = {
            form: form,
            localeId: GSAdminLocale.RegionSupportEmail,
            fieldName: RegionModelPropNames.supportemail,
            formControl: <Input />,
            initialValue: isEdit ? sourceData.supportEmail : null,
            required: true,
            rules: [FormHelper.ruleForEmail(GSAdminLocale.RegionSupportEmail)]
        };
        const orderEmailParams: FormItemInModalParams = {
            form: form,
            localeId: GSAdminLocale.RegionMaterialOrderEmail,
            fieldName: RegionModelPropNames.materialorderemail,
            formControl: <Input />,
            initialValue: isEdit ? sourceData.materialOrderEmail : null,
            required: true,
            rules: [
                FormHelper.ruleForEmail(GSAdminLocale.RegionMaterialOrderEmail)
            ]
        };
        const options = {
            formItemProps: { label: null },
            decoratorOptions: { valuePropName: "checked" }
        };
        const hasRegionGroupPermission = GLGlobal.isActionValid(
            GSAdminAction.EditRegionGroup
        );
        const hasDisabledRegionPermission = GLGlobal.isActionValid(
            GSAdminAction.EditRegionDisabled
        );

        const registrationOptions = [
            { label: fmtMsg({ id: GSAdminLocale.RegionStudentRegistrationManual }), value: StudentRegistrationType.Manual },
            { label: fmtMsg({ id: GSAdminLocale.RegionStudentRegistrationGeneric }), value: StudentRegistrationType.Generic },
        ];

        const dateFormat = 'YYYY/MM/DD';
        return <div>
            <GLForm form={form} onSubmit={this.handleSubmit.bind(this)} onChange={this.onFormChange}>
                <Row className="regionform" type="flex" align="bottom">
                    <FormItemsLayout colTotal={2}>
                        {renderFormItem(
                            form,
                            GSAdminLocale.RegionsModelName,
                            RegionModelPropNames.name,
                            <Input></Input>,
                            isEdit ? sourceData.name : null,
                            true
                        )}
                        {renderFormItem(
                            form,
                            GSAdminLocale.RegionEnglishName,
                            RegionModelPropNames.englishName,
                            <Input></Input>,
                            isEdit ? sourceData.englishName : null,
                            true
                        )}
                        {hasRegionGroupPermission &&
                            renderFormItem(
                                form,
                                GSAdminLocale.RegionGroup,
                                RegionModelPropNames.regionGroupId,
                                <Select size="large" {...alignPop()} onChange={this.onFormChange}>
                                    {...this.state.regionGroupOptions}
                                </Select>,
                                isEdit ? sourceData.regionGroupId : this.state.defaultRegionGroup,
                                false
                            )}
                        {!hasRegionGroupPermission &&
                            renderFormItem(
                                form,
                                GSAdminLocale.RegionGroup,
                                RegionModelPropNames.regionGroupId,
                                <Select size="large" disabled onChange={this.onFormChange}>
                                    {...this.state.regionGroupOptions}
                                </Select>,
                                isEdit ? sourceData.regionGroupId : this.state.defaultRegionGroup,
                                false
                            )}
                        {renderFormItem(
                            form,
                            GSAdminLocale.RegionPrimaryLanguage,
                            RegionModelPropNames.primaryLanguage,
                            <Select
                                onChange={this.onFormChange}
                                size="large"
                                {...alignPop()}
                            >
                                {...this.state.primaryLanguageOptions}
                            </Select>,
                            isEdit ? sourceData.primaryLanguage : this.state.defaultPrimaryLanguage,
                            true
                        )}
                        {renderFormItem(
                            form,
                            GSAdminLocale.RegionModelGSContentVersion,
                            RegionModelPropNames.gsVersionGroupId,
                            <Select size="large" {...alignPop()} onChange={this.onFormChange} onSelect={(gsVersionId) => this.onVersionGroupChange(form, { gsVersionId, lsVersionId })}>
                                {...this.state.gsContentVersions}
                            </Select>,
                            isEdit ? sourceData.gsVersionGroupId : this.state.defaultGSContentVersion,
                            true
                        )}
                        {renderFormItem(
                            form,
                            GSAdminLocale.RegionModelLSContentVersion,
                            RegionModelPropNames.lsVersionGroupId,
                            <Select size="large" {...alignPop()} onChange={this.onFormChange} onSelect={(lsVersionId) => this.onVersionGroupChange(form, { gsVersionId, lsVersionId })}>
                                {...this.state.lsContentVersions}
                            </Select>,
                            isEdit ? sourceData.lsVersionGroupId : this.state.defaultLSContentVersion,
                            true
                        )}
                        {renderFormItem(supportEmailParams)}
                        {renderFormItem(orderEmailParams)}
                        {renderFormItem(
                            form,
                            GSAdminLocale.TimeZone,
                            RegionModelPropNames.timezone,
                            <Select size="large" {...alignPop()} onChange={this.onFormChange}>
                                {...this.timeZoneOptions}
                            </Select>,
                            isEdit ? sourceData.timeZone : this.state.defaultTimeZone,
                            true
                        )}
                        {renderFormItem(
                            form,
                            GSAdminLocale.RegionCurrency,
                            RegionModelPropNames.currency,
                            <AutoComplete
                                onChange={this.onFormChange}
                                size="large"
                                {...alignPop()}
                                dataSource={
                                    CONSTS.RegionCurrency
                                }
                            ></AutoComplete>,
                            isEdit ? sourceData.currency : defaultCurrency,
                            true
                        )}
                        {renderFormItem(
                            form,
                            GSAdminLocale.RegionPhoneCode,
                            RegionModelPropNames.countryPhoneCode,
                            <AutoComplete
                                onChange={this.onFormChange}
                                size="large"
                                {...alignPop()}
                                dataSource={this.renderCountryOptions()}
                                onSearch={this.handleCountryCodeSearch}
                                optionLabelProp="value"
                            >
                            </AutoComplete>,
                            isEdit ? sourceData.countryPhoneCode : this.defaultPhoneCode,
                            true,
                            [{
                                validator: (rule, value: string, callback) => {
                                    if (value && value.length > 4) {
                                        callback(fmtMsg({ id: GSAdminLocale.RegionPhoneCodeLengthErr }, { length: 4 }));
                                        return;
                                    }
                                    if (value && !(/^\+[0-9]{1,3}$/.test(value.trim()))) {
                                        callback(fmtMsg({ id: GSAdminLocale.RegionPhoneCodeFormatErr }));
                                        return;
                                    }
                                    callback();
                                },
                            }]
                        )}
                        {renderFormItem(
                            form,
                            GSAdminLocale.DeviceActivationLimit,
                            RegionModelPropNames.deviceActivationLimit,
                            <InputNumber
                                min={minCount}
                                max={maxCount}
                                precision={0}
                                onChange={this.onFormChange}
                            />,
                            isEdit ? sourceData.deviceActivationLimit : defaultDeviceLimit,
                            true
                        )}
                        {renderFormItem(
                            form,
                            GSAdminLocale.DeviceDeactivationThreshold,
                            RegionModelPropNames.deviceDeactivationThreshold,
                            <InputNumber
                                min={minDeviceThreshold}
                                max={maxCount}
                                precision={0}
                                onChange={this.onFormChange}
                            />,
                            isEdit ? sourceData.deviceDeactivationThreshold : defaultDeviceThreshold,
                            true
                        )}
                        {renderFormItem(
                            form,
                            GSAdminLocale.MaxUnitsPerYear,
                            RegionModelPropNames.maxUnitsPerYear,
                            <InputNumber
                                min={minDeviceThreshold}
                                max={maxCount}
                                precision={0}
                                onChange={this.onFormChange}
                            />,
                            isEdit ? sourceData.maxUnitsPerYear : defaultMaxUnitsPerYear,
                            true
                        )}
                        {renderFormItem(
                            form,
                            GSAdminLocale.VisitationResetDate,
                            "visitationResetDate",
                            <DatePicker
                                {...alignPop({ type: "DatePicker" })}
                                onChange={this.onFormChange}
                                format={LanguageDateFormatExceptYear[GLGlobal.intl.locale]}
                                disabledDate={this.disableDates.bind(this)}
                            />,
                            isEdit ? (sourceData.visitationResetDate ? DateHelper.toLocalMoment(sourceData.visitationResetDate, dateFormat) : null) : null,
                            false,
                        )}
                        {this.props.regionId == process.env.koRegionId && renderFormItem(
                            form,
                            GSAdminLocale.RegionEOrderLink,
                            RegionModelPropNames.eorderLink,
                            <Input></Input>,
                            isEdit ? sourceData.eorderLink : null,
                            false,
                            lengthValider(GSAdminLocale.RegionEOrderLink, 150)
                        )}
                        {renderFormItem(
                            form,
                            GSAdminLocale.RegionExtendedSubscriptionPeriod,
                            RegionModelPropNames.extendedSubscriptionMonths,
                            <InputNumber
                                min={0}
                                max={99}
                                precision={0}
                                onChange={this.onFormChange}
                            />,
                            isEdit ? sourceData.extendedSubscriptionMonths : defaultExtendedSubscriptionMonths,
                            true
                        )}
                        {renderFormItem(
                            form,
                            GSAdminLocale.RegionStatus,
                            RegionModelPropNames.status,
                            <Select size="large" {...alignPop()} onChange={this.onFormChange} onSelect={(selectedRegionStatusArg) => this.onRegionStatusChange(selectedRegionStatusArg)} disabled={!hasDisabledRegionPermission}>
                                <Option key={RegionStatus.Enabled} value={RegionStatus.Enabled} title={'Enabled'}>
                                    {fmtMsg({ id: GSAdminLocale.RegionStatusEnabled })}
                                </Option>
                                <Option key={RegionStatus.Disabled} value={RegionStatus.Disabled} title={'Disabled'}>
                                    {fmtMsg({ id: GSAdminLocale.RegionStatusDisabled })}
                                </Option>
                                <Option key={RegionStatus.Test} value={RegionStatus.Test} title={'Test'}>
                                    {fmtMsg({ id: GSAdminLocale.RegionStatusTesting })}
                                </Option>
                            </Select>,
                            isEdit ? sourceData.status : RegionStatus.Enabled,
                            true
                        )}
                    </FormItemsLayout>
                    <div className="billing-details lesson-versions">
                        <div className="billing-details__title">{fmtMsg({ id: GSAdminLocale.RegionModelLessonPlanVersions })}</div>
                        <FormItemsLayout colTotal={2}>
                            {renderFormItem(
                                form,
                                GSAdminLocale.RegionModelLessonPlanVersion,
                                RegionModelPropNames.lessonPlanVersion,
                                <Select size="large" {...alignPop()} onChange={this.onFormChange}>
                                    {
                                        (this.state.lessonPlanVersions || []).map(
                                            version => this.getOption(version.name, version.id)
                                        )
                                    }
                                </Select>,
                                isEdit ? sourceData.gsLessonPlanVersionId : defaultLessonPlanVersion,
                                true
                            )}
                            {renderFormItem(
                                form,
                                GSAdminLocale.RegionModelREPMethodologyVersion,
                                RegionModelPropNames.repMethodologyVersion,
                                <Select size="large" {...alignPop()} onChange={this.onFormChange}>
                                    {
                                        (this.state.repMethodologyVersions || []).map(
                                            version => this.getOption(version.name, version.id)
                                        )
                                    }
                                </Select>,
                                isEdit ? sourceData.repMethodologyVersionId : defaultREPMethodologyVersion,
                                true
                            )}
                            {renderFormItem(
                                form,
                                GSAdminLocale.RegionModelRemoteLessonPlanVersion,
                                RegionModelPropNames.remoteLessonVersion,
                                <Select size="large" {...alignPop()} onChange={this.onFormChange}>
                                    {
                                        (this.state.remoteLessonVersion || []).map(
                                            version => this.getOption(version.name, version.id)
                                        )
                                    }
                                </Select>,
                                isEdit ? sourceData.remoteLessonPlanVersionId : defaultRemoteLessonVersion,
                                true
                            )}
                            {renderFormItem(
                                form,
                                GSAdminLocale.RegionModelDigitalLessonPlanVersion,
                                RegionModelPropNames.digitalLessonVersion,
                                <Select size="large" {...alignPop()} onChange={this.onFormChange}>
                                    {
                                        (this.state.digitalLessonVersion || []).map(
                                            version => this.getOption(version.name, version.id)
                                        )
                                    }
                                </Select>,
                                isEdit ? sourceData.digitalLessonPlanVersionId : defaultDigitalLessonVersion,
                                true
                            )}
                        </FormItemsLayout>
                    </div>
                    <Row className="student-registration">
                        <FormItemsLayout>
                            {renderFormItem(
                                form,
                                GSAdminLocale.RegionStudentRegistrationText,
                                RegionModelPropNames.studentRegistrationSelectedValues,
                                <CheckBoxGroup options={registrationOptions} />,
                                this.state.registrationSelectedValues,
                                null,
                                [{
                                    required: true,
                                    message: fmtMsg({ id: GSAdminLocale.RegionStudentRegistrationValidation })
                                }]
                            )}
                        </FormItemsLayout>
                    </Row>
                    <div className="billing-details">
                        <div className="billing-details__title">{fmtMsg({ id: GSAdminLocale.RegionBillingDetails })}</div>
                        <FormItemsLayout colTotal={2}>
                            {renderFormItem(
                                form,
                                GSAdminLocale.RegionBillingDay,
                                RegionModelPropNames.billingDay,
                                <Select
                                    onChange={this.onFormChange}
                                    size="large"
                                    {...alignPop()}
                                >
                                    {...this.billingDayOptions}
                                </Select>,
                                isEdit ? sourceData.billingDay : this.state.defaultBillingDay,
                                true
                            )}
                            {renderFormItem(
                                form,
                                GSAdminLocale.RegionBillingPeriod,
                                RegionModelPropNames.billingPeriod,
                                <Select size="large" {...alignPop()} onChange={this.onFormChange}>
                                    {...this.billingPeriodOptions}
                                </Select>,
                                isEdit ? sourceData.billingPeriod : null,
                                true
                            )}
                            {renderFormItem(
                                form,
                                GSAdminLocale.RegionBillingStartDay,
                                RegionModelPropNames.billingStartDay,
                                <Select size="large" {...alignPop()} onChange={this.onFormChange}>
                                    {...this.billingDayOptions}
                                </Select>,
                                isEdit ? sourceData.billingStartDay : null,
                                true
                            )}
                            {renderFormItem(
                                form,
                                GSAdminLocale.RegionBillingStartMonth,
                                RegionModelPropNames.billingStartMonth,
                                <Select size="large" {...alignPop()} onChange={this.onFormChange}>
                                    {...this.billingMonthOptions}
                                </Select>,
                                isEdit ? sourceData.billingStartMonth : null,
                                true
                            )}
                        </FormItemsLayout>
                    </div>
                    <div className="manage-section">
                        <div className="event-manager">
                            {eventSettings && eventSettings.length > 0 && <div className="content-right">
                                <a
                                    className="content-right"
                                    onClick={this.toggleEventManager}
                                >
                                    {fmtMsg({
                                        id: GSAdminLocale.EventManagerLinkText
                                    })}
                                </a>
                            </div>}
                            {this.state.eventManagerVisible && <EventManager isReadOnly={isReadOnly} visible={this.state.eventManagerVisible} toggleEventManager={this.toggleEventManager} />}
                        </div>
                        <div className="link-manager">
                            <div className="content-right">
                                <a
                                    className="content-right"
                                    onClick={this.toggleLinkManager}
                                >
                                    {fmtMsg({
                                        id: LinkManager.Title
                                    })}
                                </a>
                            </div>
                            <LinkManagerComponent onDelete={this.onFormChange} isReadOnly={isReadOnly} isEdit={this.props.isEdit} visible={this.state.visible} regionId={this.props.regionId} toggleLinkManager={this.toggleLinkManager} />
                        </div>
                    </div>
                </Row>
                <Table
                    showHeader={false}
                    pagination={false}
                    columns={this.getTableColumns()}
                    dataSource={this.getTableData()}
                    bordered
                />
                <SubmitBtns
                    isDisabled={this.state.disableSaveButton}
                    cancelTitle={GSAdminLocale.ButtonCancel}
                    onCancel={() => this.backToList()}
                />
            </GLForm>
        </div>
    }
}
